<template>
  <v-col
    class="col-12 main-content pa-0"
  >
    <!-- Maintenance Mode Announcement -->
    <v-row
      v-if="maintenanceMode"
      class="justify-center ma-0"
    >
      <v-col>
        <v-card
          class="elevation-0"
        >
          <v-card-text>
            <v-row
              class="justify-center"
            >
              <v-col>
                <v-icon
                  class="pr-3"
                >
                  info_outline
                </v-icon>

                <span>
                  Maintenance Mode is currently enabled.
                  You may encounter minor issues while maintenance is in progress.
                  Email tech@adops.com if you have any questions.
                </span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <summary-card-bar v-if="showSummaries" />

    <v-row
      v-if="showMessage"
      class="ma-0"
    >
      <error-unknown :status-message="currentStatusMessage" />
    </v-row>

    <v-row
      v-else-if="isLoading"
      class="ma-0 fill-height"
    >
      <message-view />
    </v-row>

    <keep-alive>
      <component
        :is="currentComponent"
        v-if="showContent"
      />
    </keep-alive>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import PATHS from 'src/router/paths'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapActions: mapMessagesActions, mapGetters: mapMessagesGetters } = createNamespacedHelpers('messages')
const { mapActions: mapToolsActions, mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

// noinspection ES6MissingAwait
export default {
  name: 'MainContent',
  components: {
    AdxAndOb: () => import('src/programmatic/views/Yield'),
    AdxHealth: () => import('src/programmatic/views/Health'),
    Backlogs: () => import('src/docs/views/Backlogs'),
    Creatives: () => import('src/admanager/views/Creatives'),
    CustomReports: () => import('src/custom-reports/views/CustomReports'),
    Dashboard: () => import('src/dashboard/views/Dashboard'),
    DataRefresh: () => import('src/docs/views/DataRefresh'),
    Deprecation: () => import('src/docs/views/Deprecation'),
    Docs: () => import('src/docs/views/Docs'),
    Discrepancy: () => import('src/delivery/views/Discrepancy'),
    ErrorUnknown: () => import('src/messages/components/ErrorUnknown'),
    Explore: () => import('src/internal/views/ExploreSheets'),
    Home: () => import('src/dashboard/views/Dashboard'),
    Lineitems: () => import('src/admanager/views/LineItems'),
    MessageView: () => import('src/messages/components/MessageView'),
    NetworkHealth: () => import('src/programmatic/views/Health'),
    Networks: () => import('src/admanager/views/Networks'),
    Organization: () => import('src/internal/views/Organization'),
    Orders: () => import('src/admanager/views/Orders'),
    Overview: () => import('src/internal/views/Overview'),
    Pacing: () => import('src/delivery/views/Pacing'),
    Person: () => import('src/internal/views/Person'),
    PrivacyPolicy: () => import('src/docs/views/PrivacyPolicy'),
    Project: () => import('src/internal/views/Project'),
    Releases: () => import('src/docs/views/Releases'),
    SummaryCardBar: () => import('src/tools/components/summary/SummaryCardBar'),
    TimePage: () => import('src/internal/views/Time'),
    YieldPartner: () => import('src/programmatic/views/Yield'),
    Whitelisting: () => import('src/docs/views/Whitelisting'),
  },
  data() {
    return {
      maintenanceMode: false,

      moduleMap: {
        'custom-reports': 'customReports',
      },

      isHydrating: false,
      pendingHydration: false,

      reportNameMap: {
        'campaign-tracking': 'custom-reports',
        'campaign-tracking-1': 'custom-reports',
        'campaign-tracking-2': 'custom-reports',
        'monthly-progress': 'custom-reports',
        'progress-report': 'custom-reports',
        revenue: 'custom-reports',
        'revenue-at-risk': 'custom-reports',
        'schneps-mid-month': 'custom-reports',
        time: 'time-page',
      },
    }
  },
  computed: {
    ...mapAuthGetters(['inDemoMode', 'isInternalUser']),
    ...mapMessagesGetters(['currentMessage', 'statusCodes']),
    ...mapToolsGetters([
      'currentColumns',
      'currentItems',
      'currentViewId',
      'isCustomReportRoute',
      'isDocsRoute',
      'isReportIdRequiredOnRoute',
      'reportKey',
      'routeName',
      'showCurrentSavedFilters',
    ]),
    ...mapToolsState({
      isLoadingSearch: (state) => state.isLoadingSearch,
      selectedSearch: (state) => state.selectedSearch,

      adjuster: (state) => state.adjuster,
      reportId: (state) => state.reportId,
      subId: (state) => state.subId,
    }),
    reportName() {
      return this.selectedSearch?.reportName
    },
    reportPath() {
      return this.selectedSearch?.reportPath
    },
    isCustomReportRouteName() {
      return this.isCustomReportRoute || this.$route.name === 'custom-reports'
    },
    currentComponent() {
      // We rename Time to avoid naming collisions in the browser.
      return this.reportNameMap[this.reportKey] || this.reportKey
    },
    currentParams() {
      const { adjuster, reportId, reportKey, reportName, reportPath, routeName, subId } = this

      return {
        adjuster,
        dateRange: null,
        reportId,
        reportKey,
        reportName,
        reportPath,
        routeName,
        subId,
      }
    },
    currentReportId() {
      return this.currentParams?.reportId
    },
    currentSubId() {
      return this.currentParams?.subId
    },
    isLoading() {
      const {
        currentColumns,
        currentMessage,
        currentItems,
        currentViewId,
        isCustomReportRouteName,
        isDocsRoute,
        isLoadingSearch,
      } = this

      const requiresLoading = () => !isCustomReportRouteName && !isDocsRoute
      const isPendingSearch = () => isLoadingSearch && 'search'
      const isPendingSetup = () => (!currentViewId || !currentColumns || !currentMessage) && 'setup'
      const isLoadingData = () => currentItems === undefined && 'data'
      const isMessaging = () => currentMessage?.isActive && 'message'

      // return requiresLoading() && (isPendingStore() || isPendingSetup() || isLoadingData() || isMessaging())
      return requiresLoading() && (isPendingSearch() || isPendingSetup() || isLoadingData() || isMessaging())
    },
    showProfilePage() {
      const internalReports = ['organization', 'person', 'project']
      return internalReports.indexOf(this.$route.params.reportName) !== -1 && this.currentReportId
    },
    showContent() {
      const { currentMessage, isCustomReportRouteName, isDocsRoute } = this
      const isDashboardRoute = () => this.selectedSearch?.dataType === 'custom'
      const isCustomRoute = isDashboardRoute() || isCustomReportRouteName || isDocsRoute

      return isCustomRoute || currentMessage?.isActive === false
    },
    showSummaries() {
      const { showCurrentSavedFilters, showMessage, showProfilePage } = this
      return showCurrentSavedFilters && !showMessage && !showProfilePage
    },
    hasNoReport() {
      const {
        isInternalUser,
        isReportIdRequiredOnRoute,
        reportKey,
        selectedSearch,
      } = this

      const requirementsMap = {
        [PATHS.ADMANAGER.CREATIVES]: (searchItem) => !!searchItem.networkCode,
        [PATHS.ADMANAGER.LINEITEMS]: (searchItem) => !!searchItem.networkCode,
        [PATHS.ADMANAGER.NETWORKS]: (searchItem) => !!searchItem.networkCode,
        [PATHS.ADMANAGER.ORDERS]: (searchItem) => !!searchItem.networkCode,

        [PATHS.DASHBOARD.BASE]: (searchItem) => !!searchItem,
        [PATHS.DASHBOARD.HOME]: (searchItem) => !!searchItem,

        [PATHS.INTERNAL.ORGANIZATION]: (searchItem) => !!searchItem && isInternalUser,
        [PATHS.INTERNAL.PERSON]: (searchItem) => !!searchItem && isInternalUser,
        [PATHS.INTERNAL.PROJECT]: (searchItem) => !!searchItem && isInternalUser,
        [PATHS.INTERNAL.TIME]: (searchItem) => !!searchItem && isInternalUser,

        // Network Reports
        [PATHS.REPORTS.DISCREPANCY]: (searchItem) => !!searchItem.hasDiscrepancy,
        [PATHS.REPORTS.PACING]: (searchItem) => !!searchItem.hasPacing,
        [PATHS.REPORTS.ADX_HEALTH]: (searchItem) => !!searchItem.hasHealthAdx,
        [PATHS.REPORTS.NETWORK_HEALTH]: (searchItem) => !!searchItem.hasHealthNetwork,
        [PATHS.REPORTS.YIELD_PARTNER]: (searchItem) => !!searchItem.hasYieldPartner,
      }

      const hasNoReport = () => {
        const noOptionSelected = () => !selectedSearch
        const isOptionSelected = () => typeof selectedSearch === 'object'
        const meetsReportRequirement = () => requirementsMap[reportKey](selectedSearch) // typeof requirementsMap[reportKey] === 'function'
        const optionFailedRequirement = () => selectedSearch.dataType !== 'custom' && meetsReportRequirement() === false

        return noOptionSelected() || (isOptionSelected() && optionFailedRequirement())
      }

      return isReportIdRequiredOnRoute && hasNoReport()
    },
    showMessage() {
      return !this.isLoading && (this.hasNoReport || !this.showContent)
    },
    currentStatusMessage() {
      return this.hasNoReport ? `No Report Available for ${this.selectedSearch?.text || 'Selected'}` : 'Error Unknown'
    },
  },

  watch: {
    inDemoMode() {
      this.renderMainContent(this.$route)
    },
    // routeName(newValue, oldValue) {
    //   if (newValue !== oldValue) {
    //     this.preconfigure(newValue)
    //   }
    // },
    // selectedSearch: {
    //   deep: true,
    //   handler(newValue, oldValue) {
    //     const requireHydration = () => {
    //       const isPendingHydration = () => this.pendingHydration && !this.isHydrating
    //       const hasChangedSelection = () => !this.pendingHydration
    //           && this.hasChangedSelectedSearch({ newValue, oldValue })
    //
    //       return isPendingHydration() || hasChangedSelection()
    //     }
    //
    //     if (this.isReportIdRequiredOnRoute) {
    //       this.pendingHydration = true
    //       this.configureMainContent(this.$route)
    //     } else {
    //       this.renderMainContent(this.$route)
    //     }
    //
    //     if ((this.isReportIdRequiredOnRoute || this.reportId) && requireHydration()) {
    //       this.isHydrating = true
    //       this.pendingHydration = true
    //       this.renderMainContent(this.$route).then(() => {
    //         this.pendingHydration = false
    //         this.isHydrating = false
    //       })
    //     }
    //   },
    // },
    // @clean
    // $route(newValue, oldValue) {
    //   if (this.hasChangedSelectedSearch({ newValue, oldValue })) {
    //     this.renderMainContent(this.$route)
    //   }
    // },
    // isLoadingSearch: {
    //   immediate: true,
    //   handler(newValue) {
    //     if (newValue === false) {
    //       if (this.isReportIdRequiredOnRoute) {
    //         this.pendingHydration = true
    //         this.configureMainContent(this.$route) // The watch on selectedSearch will handle the render.
    //       } else {
    //         this.renderMainContent(this.$route)
    //       }
    //     }
    //   },
    // },
  },
  // @clean
  // created() {
  //   if (this.isReportIdRequiredOnRoute) {
  //     this.pendingHydration = true
  //     this.configureMainContent(this.$route) // The watch on selectedSearch will handle the render.
  //   } else {
  //     this.renderMainContent(this.$route)
  //   }
  // },
  methods: {
    ...mapMessagesActions([
      'showLoadingMessage',
      'showStatusMessage',
    ]),
    ...mapToolsActions([
      'configureMainContent',
      'getParams',
      'hasChangedSelectedSearch',
      'isInvalidRequest',
      'renderMainContent',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

.main-content {
  width: 100%;
  height: 100%;

  h1 {
    margin-bottom: 16px;
  }
}

.error-message,
.info-message {
  padding: 48px;
  background-color: $color-monochrome-white;
  border-radius: 6px;
}

.messenger > div {
  display: flex;
  align-items: center;

  .v-icon {
    margin-right: 6px;
  }
}
</style>
